.PokemonSlotDisplay{
  position: relative;
  width: 246px;
  height: 156px;
  margin: auto;
  text-align: center;
}


.PokemonSlotDisplay img{
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
}
