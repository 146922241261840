.DamageCardDamageSlot{
  width: 110px;
  height: 18px;
  padding: 15px 0px;
}

.DamageCardDamageContainer{
  width: 110px;
  margin: auto;
}


.DamageSlotMinDamage{
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-align: right;
  display: inline-block;
  width: 45px;
}
.DamageSlotHyphen{
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-align: center;
  display: inline-block;
  width: 20px;
}

.DamageSlotMaxDamage{
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-align: left;
  display: inline-block;
  width: 45px;
}