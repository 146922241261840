body{
    margin: 10px 0px;
    font-family: 'Roboto', sans-serif;
}

@media screen {
    .App{
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 310px 620px 310px;
        grid-template-areas:
            "logo logo logo"
            "box box box"
            "slot1 damage slot2"
            "notes notes notes";
        align-items: baseline;
        justify-content: center;
    }
    

}



@media screen and (max-width: 1285px) {
    .App{
        display: grid;
        grid-template-columns: 310px 310px;
        grid-gap: 10px;
        grid-template-areas: 
            "logo logo"
            "box box"
            "slot1 slot2"
            "damage damage"
            "notes notes";
        align-items:baseline;
        justify-content: center;
    }
    
}

@media screen and (max-width: 625px) {
    .App{
        display: grid;
        grid-template-columns: 308px;
        grid-gap: 10px;
        grid-template-areas: 
            "logo"
            "box"
            "slot1"
            "slot2"
            "damage"
            "notes";
        align-items:baseline;
        justify-content: center;
    }
    
}

