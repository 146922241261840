.SelectAxisButton{
  margin: 10px;
  display: inline-block;
  width: 42%;
}

.SpreadOptimizationPlot{
  border: 1px solid #ccc;
  overflow-x:auto;
  width: 95%;
  height: 600px;
}

@media screen and (max-width:500px) {
  .SpreadOptimizationPlot{
    border: 1px solid #ccc;
    overflow-x:auto;
    width: 95%;
    height: 300px;
  }
}