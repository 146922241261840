.MoveSelectionLine{
  display: grid;
  align-items: center;
  grid-template-areas: "selection crit";
}

.MoveSelectionAutocomplete{
  grid-area: selection;
  width: 210px;
}

.MoveSelectionCritButton{
  width: 60px;
  grid-area: crit;
}