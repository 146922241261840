.CardSlot{
  border: 1px solid #ccc;
  border-radius: 16px;
  width:310px;
  overflow: hidden;
}

#slot1{
  grid-area: slot1;
}

#slot2{
  grid-area: slot2;
}


.buttonBar{
  width: 90%;
  margin: 5%;
  display: grid;
  align-items: center;
  grid-template-columns: 90px 75px 50px 90px;
  grid-template-areas: 
  "curhp-label curhp-input curhp-num curhp-perc";
}

.curhp_label{
  grid-area: curhp-label;
}

.curhp_input{
  grid-area: curhp-input;
}

.curhp_num{
  grid-area: curhp-num;
}

.curhp_perc{
  grid-area: curhp-perc;
}

