.BoxCard{
  width: 128px;
  height: 128px;
  border: 1px solid #ccc;
  border-radius: 16px;
  margin: 2px;
  text-align: center;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.7);
}

.PokemonName{
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  height: 20px;
  width: 100%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.PokemonAnimation{
  position: relative;
  width: 100%;
  height: 68px;
}

.PokemonAnimation img{
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 100%;
  max-height: 100%;
}

.Buttons{
  height: 30px;
  width: 100%;
}