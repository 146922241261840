.NoteCard{
  width: 304px;
  border: 1px solid #cc1100;
  border-radius: 16px;
  margin: 2px;
  padding: 4px;
  text-align: center;
}

.MiniCard{
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.NoteCardImg{
  width: 128px;
  height: 128px;
  margin: 2px;
  position: relative;
  overflow: hidden;
}

.NoteCardImg img{
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.NoteCardTextSlot{
  min-height: 24px;
  text-align: left;
  padding-left: 10px;
  max-width: 128px;
  border-top: 1px solid #ccc;
}

.NoteCardArrow{
  display: inline-block;
  margin: 0px;
}

.NoteCardArrow > * {
  margin: 85px 0px;
}

.NoteCardDamageText{
  margin: 4px 0px;
  border-top: 1px solid #ccc;
}

.NoteCardButton{
  text-align: right;
}