.ses_statname{
  grid-area: name;
  font-size: 11pt;
}

.ses_statvalue{
  grid-area: base;
  font-size: 11pt;
  text-align: right;
  margin: 0px 8px 0px 0px;
}


.ses_iv{
  grid-area: iv;
  margin: 0px 5px 0px 0px;
}

.ses_ev{
  grid-area: ev;
  margin: 0px 5px 0px 0px;
  transition: all 250ms;
}

.ses_evslider{
  grid-area: slider;
}

.ses_total{
  grid-area: tot;
  text-align: right;
}

.ses_stage{
  grid-area: stage;
}

.BumpIcon{
  grid-area: icon;
  text-align: right;
}

.StatElementSlider{
  display: grid;
  grid-template-areas: 
    "name base iv ev stage icon"
    "name slider slider slider slider tot ";
  grid-template-columns: 40px 44px 44px 52px 36px 42px;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  margin: 2px 2px;
  padding: 3px 0px;
  border-top: 1px solid #ccc;
}


.positive_boost{
  color: #00b232;
  font-weight: bold;
}

.negative_boost{
  color: #ff1919;
  font-weight: bold;
}

.wrong_evs{
  color: #ff1919;
}