.Box{
  grid-area: box;
  width: 100%;
}

.pc_button{
  width: 64px;
  height: 64px;
  text-align: center;
}

.BoxBar{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ImportContainer{
  flex: 0 0 370px;
}

#InputShowdown{
  font-size: 12pt;
  line-height: normal;
}

.BoxContainer{
  border: 3px solid greenyellow;
  justify-content: center;
  min-height: 140px;
  max-height: 390px;
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width:1285px) {
  .ImportContainer{
    flex: 0 0 600px;
  }    
}

@media screen and (max-width:600px) {
  .ImportContainer{
    flex: 0 0 290px;
  }    
}