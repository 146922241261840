.Logo{
  grid-area: logo;
}

.LogoInner{
  width: 550px;
  margin: auto;
}

.LogoImg{
  display: inline-block;
  max-height: 128px;
}

.LogoText{
  display: inline-block;
  width: 400px;
}

.MainText{
  font-size: 66pt;
}
.SubText{
  font-size: 24pt;
}

.pink{
  color: #f8aaa8;
  font-weight: bold;
}

.grey{
  color: #bcbcbc;
}


@media screen and (max-width:625px) {
  .LogoInner{
    width: 275px;
    margin: auto;
  }
  
  .LogoImg{
    display: inline-block;
    max-height: 150px;
    max-width: 75px;
  }
  
  .LogoText{
    display: inline-block;
    width: 200px;
  }
  
  .MainText{
    font-size: 32pt;
  }
  .SubText{
    font-size: 11.5pt;
  }
}
