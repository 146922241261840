.PokemonBuilder{
  width: 100%;
}

.PokemonBuilder ul{
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}


.StatList{
  border-bottom: 1px solid #ccc;
}

.Moveset{
  border-bottom: 1px solid #ccc;
}

.TotalEV{
  text-align: center;
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.red{
  color: red;
}

.PokemonBuilder_Grid{
  margin-bottom: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 60px 1fr;
  grid-gap: 10px;
  grid-template-areas:
    "species species"
    "name name"
    "level_label level"
    "ability_label ability"
    "item_label item";
}

.PokemonBuilder_PokemonAbility{
  grid-area: ability;
}

.PokemonBuilder_PokemonAbilityLabel{
  grid-area: ability_label;
}

.PokemonBuilder_PokemonItem{
  grid-area: item;
}

.PokemonBuilder_PokemonItemLabel{
  grid-area: item_label;
}

.PokemonBuilder_PokemonLevel{
  grid-area: level;
}

.PokemonBuilder_PokemonLevelLabel{
  grid-area: level_label;
}

.PokemonBuilder_PokemonSpecies{
  grid-area: species;
}

.PokemonBuilder_PokemonName{
  grid-area: name;
}

.Dynamax{
  text-align: center;
}