.Notes{
  grid-area: notes;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 16px;
}

.NoteList{
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: center;
}