.Field{
  margin: 5px;
  width: 100%;
  text-align: center;
}

.FieldButtonList{
  margin: 5px;
}

.TerrainIcon{
  max-width: 48px;
}

.MoveNameButton{
  white-space: nowrap;
  overflow:hidden;
  max-width: 100%;
}

.DamagePanel{
  grid-area: damage;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #ccc;
  border-radius: 16px;
  max-width: 700px;
  min-width: 300px;
}

.DamageCard{
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  border-radius: 16px;
  margin: 4px 0px;
  width: 290px;
  height: 290px;
}

.DamageCardName{
  width: 100%;
  text-align: center;
  font-size: 14pt;
  margin: 5px;
  text-transform: capitalize;
}

.DamageCardMoves{
  width: 160px;
  margin: 2px;
}

.DamagePanelDamages{
  width: 120px;
}

.DamageText{
  border: 1px solid #ccc;
  padding: 8px;
  grid-template-columns: 90% 30px;
  grid-template-areas: "damagetext_text damagetext_button";
  border-radius: 16px;
  margin:4px;
  display: grid;
  align-items: center;
}

.SpreadOptimization{
  width: 95%;
  border: 1px solid #ccc;
  border-radius: 16px;
  margin: auto;
  margin-bottom: 10px;
  min-height: 600px;
  padding: 10px;
}

.DamageText_Text{
  grid-area: damagetext_text;
  text-align: center;
  width: 90%;
}

.DamageText_Button{
  grid-area: damagetext_button;
  text-align: right;
}



@media screen and (max-width:500px) {
  .SpreadOptimization{
    width: 95%;
    border: 1px solid #ccc;
    border-radius: 16px;
    margin: auto;
    margin-bottom: 10px;
    min-height: 300px;
    padding: 10px;
  }
}