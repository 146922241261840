/*
ANIMATIONS
*/

@keyframes search_button_animation_slide{
    from {
        opacity: 0.0;
        right: 136px;}
    to {
        opacity: 1.0;
        right: 10px;}
}

@keyframes search_button_animation_fadein{
    from {width: 0px;}
    to {width: 120px;}
}

/*
CSS
*/

.SearchButton{
    margin: 1px;
    width: 300px;
    min-width: 300px;
    min-height: 36px;
    position: relative;
}

#pokeball_search_button{
    background-color: #ffffff00;
    border: 0px;
    margin: 0px;
    padding: 0px;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0px;
    left: 0px;
}


#pokeball_search_button img{
    max-width: 36px;
    max-height: 36px;
    margin: 0px;
    transform: rotate(0deg);
    transition: all 1s;
}

#pokeball_search_button img.animate{
    transform: rotate(360deg);
}


.pokemon_search_input{
    font-size: 11pt;
    border: 1px solid #ccc;
    border-radius: 18px;
    position: absolute;
    top: 5px;
    left: 10px;
    transition: all 1s;
}

.pokemon_search_input.animate{
    width: 220px;
    padding: 0px 0px 0px 30px;
}


#pokemon_search_button{
    background-color: #ffffff00;
    border: 0px;
}

#pokemon_search_button img{
    position: absolute;
    top: 10px;
    right: 8px;
    max-width: 24px;
    max-height: 24px;
}


.SearchList{
    position: absolute;
    width: 156px;
    top: 36px;
    z-index: 10;
    background-color: #ccc;
}